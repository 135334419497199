:root {
  font-size: 16px;
  --backgroundColor: #fff; /*#f6f9fc;*/
  --sectionBackgroundColor: rgba(0,189,142,.1);
  --textColor: #0c0f0a; /*#1c1d21;*/
  --textColorSecondary: #0c0f0a; /*rgb(81, 89, 108);*/
  --textColorGray: rgb(81, 89, 108);
  --grayColor: #f9fafc; /*#f7f7f8;*/
  --baseFontFamily: 'Poppins', serif;
  --whiteColor: #fff;
  --lightGreen: rgba(0,189,142,.1);
  --mediumGreen: rgba(0,189,142,.7);
  --green: #008060;
  --greenHover: hsl(165, 100%, 30%);
  --darkGreen: #005c45;
  --shinyGreen: #00bd8e;
  --alertColor: #f8d7da;
  --alertColorHover: #f5c6cb;
  --alertColorText: hsl(345, 100%, 50%);
  --infoColor: #cfe2ff;
  --infoColorHover: #b6d4fe;
  --infoColorText: #084298;
  --warningColor: #ffc107;
  --warningColorHover: hsl(45, 100%, 61%);
  --warningColorText: hsl(45, 100%, 21%);
}

::selection {
    color: var(--green);
    background-color: var(--lightGreen);
}

::-webkit-scrollbar {
  inline-size: .5rem;
  block-size: .35rem;
}

/* ::-webkit-scrollbar-button {

} */

::-webkit-scrollbar-thumb {
  background: var(--lightGreen);
}

::-webkit-scrollbar-track {
  background: var(--grayColor);
}

body {
  margin: 0;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  font-family: var(--baseFontFamily);
}

.wrapper {
  height: inherit;
  min-height: inherit;
  padding: 0 30px;
  margin: 0 auto;
}

.wrapper__form {
  height: inherit;
  min-height: inherit;
  padding: 0 12px;
  margin: 0 auto;
}

.section {
  width: 100%;
  padding: 4rem 0rem;
  box-sizing: border-box;
}

.section__top {
  width: 100%;
  padding: 4rem 0rem 0rem;
  box-sizing: border-box;
}

.section--inverse {
  background-color: var(--sectionBackgroundColor);
}

.section--dark {
  background-color: var(--darkGreen);
}

.section--green {
  background-color: var(--lightGreen);
}

.section--empty {
  padding: 0;
}

.section__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-end: 2.5rem;
}

.section_center {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.section--start {
  align-items: flex-start;
}

.sectionAdmin {
  background-color: rgba(241, 185, 128, .1) !important;
  width: 100%;
  padding: 4rem 0 4rem;
  box-sizing: border-box;
}

.link {
  position: relative;
  color: var(--green);
  text-decoration: none;
}

.link::hover {
  color: var(--darkGreen);
}

.link::before {
  content: "";
  position: absolute;
  bottom: -3px;
  background-color: var(--darkGreen);
  height: 1px;
  transition: transform 0.5s;
  z-index: 20;
  width: 100%; /*calc(100% - 36px);*/
  transform: scaleX(0);
  will-change: transform;
}

.link:hover::before {
  transform: scaleX(1);
}

@media (min-width:768px) {
  .wrapper {
    padding: 0 100px;
  }
  .section__content {
    flex-direction: row;
    justify-content: space-around;
    gap: 4rem;
    flex-wrap: wrap;
  }
}

@media (min-width:1024px){
  .wrapper {
    padding: 0 80px;
  }
}

@media (min-width:1200px){
  .wrapper {
    max-width: 1280px;
    padding: 0 55px;
  }
}

@media (min-width:1400px){
  .wrapper {
    padding: 0;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 1s linear infinite;
  margin-right: 10px;
}