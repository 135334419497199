.badge {
  display: block;
  padding: 0.2rem 0.35rem;
  font-size: .75rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge--success {
  color: white; /*rgb(25, 135, 84);*/
  background-color:#00bd8e; /* rgba(25, 135, 84, 0.1);*/
}

.badge--pending {
  color: var(--textColor);
  background-color: #ffe484;
}

.badge--alert {
  background-color: var(--alertColor);
  color: var(--alertColorText);
}