.heroTextImage {
  position: relative;
  background-color: var(--darkGreen);
  margin-block-end: 6rem;
}

.heroTextImage__image img {
  max-width: 100%;
  height: auto;
}

.heroTextImage__head {
  padding-block-start: 3rem;
  padding-block-end: 3rem;
}

.heroTextImage__subtitle {
  color: var(--grayColor);
  text-transform: uppercase;
  font-size: 14px;
}

.heroTextImage__title {
  font-weight: 300;
  line-height: 1.2;
  font-size: 2.25rem;
  color: var(--whiteColor);
  margin-block-start: 0;
}

.heroTextImage__strong {
  color: rgb(241,185,128);
}

.heroImage__content {
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 56.25%;
  -webkit-perspective: 100px;
  -moz-perspective: 100px;
  perspective: 100px;
  /* padding-block-end: 100%; */
}

.heroImage__content:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
}

@media (min-width: 1024px) {

  .heroTextImage {
    display: flex;
    flex-direction: row-reverse;
  }

  .heroTextImage__image {
    position: relative;
    width: 50%;
    height: inherit;
    flex: 0 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .heroTextImage__cover {
    position: absolute;
  }

  .heroTextImage__image img {
    max-width: 100%;
    height: 100%;
    width: auto;
  }

  .heroTextImage__head {
    padding-block-start: 4rem;
    padding-block-end: 4rem;
  }

  .heroTextImage__title {
    font-size: 1.7rem;
  }

}

@media (min-width: 1200px) {
  .heroTextImage__head {
    padding-block-start: 7rem;
    padding-block-end: 7rem;
  }
}

@media (min-width: 1300px) {
  .heroTextImage__head {
    padding: 7rem;
    padding-inline-end: 0;
    max-width: 600px;
  }

  .heroTextImage__title {
    font-size: 2.75rem;
  }
}