.brief {
  position: relative;
  margin-block-start: 40px;
  margin-block-end: 40px;
}

.brief__content {
  display: flex;
  flex-direction: column;
}

.brief__header {
  margin-block-end: 30px;
}

.brief__title {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: none;
}

.brief__description {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1.8px;
  text-transform: none;
}

@media (min-width:1024px){
  .brief__content {
    flex-direction: row;
  }

  .brief__text {
    padding-block-start: 80px;
    padding-block-end: 80px;
    padding-inline-start: 50px;
  }

  .brief__header {
    min-width: 400px;
  }
}