.cardFeature {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  height: inherit;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0,34,28,.1);
  border-radius: 0.3125rem;
  background-color: var(--whiteColor);
  color: var(--textColor);
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  transition:all .2s ease-in-out;
  text-decoration: none;
  overflow: hidden;
  margin-block-end: 2rem;
  width: 335px;
  cursor: pointer;
  padding: 2rem 2rem 3rem;
  box-sizing: border-box;
}

.cardFeature:focus, .cardFeature:hover {
  -webkit-transform:translateY(-1rem);
  transform:translateY(-1rem) ;
}

.cardFeature__head {

}

.cardFeature__image {

}

.cardFeature__body {

}

.cardFeature__title {
  margin-block-start: 1.5rem;
  margin-block-end: 0rem;
  font-size: 26px;
  font-weight: 400;
}

.cardFeature__description {
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.8;
}

@media (min-width: 768px) {
  .cardFeature {
    box-sizing: content-box;
  }
}

@media (min-width: 1024px) {
  .cardFeature {
    width: 266px;
    height: 550px;
  }
}

@media (min-width: 1200px) {
  .cardFeature {
    width: 312px;
    height: 550px;
  }
}

@media (min-width: 1280px) {
  .cardFeature {
    width: 300px;
    height: 550px;
  }
}
