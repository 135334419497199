.partners {
  text-align: center;
  box-sizing: border-box;
}

.partners__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.partners__figure {
  flex: 1 0 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners__img, .partners__img--little {
  width: 6.55rem;
  height: auto;
  border-radius: 0;
}



@media (min-width: 1024px) {
  .partners__img--little {
    width: 7rem;
  }

  .partners__img {
    width: 9rem;
  }
}

@media (min-width: 1280px) {
  .partners__img {
    width: 12rem;
  }
}