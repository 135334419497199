.bannerSocial {
  text-align: center;
  box-sizing: border-box;
}

.bannerSocial__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bannerSocial__containerFigure {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.bannerSocial__figure, .bannerSocial__text {
  flex: 1 0 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bannerSocial__text {
  flex: 2 0;
}

.bannerSocial__title {
  font-weight: 500;
  line-height: 2;
  font-size: 24px;
  text-align: text;
}

.bannerSocial__figure:last-child {
  border-right: none;
}

.bannerSocial__figure svg {
  color: var(--darkGreen);
  width: 6rem;
  height: auto;
  border-radius: 0;
}

@media (min-width:768px) {
  .bannerSocial__text {
      flex: 1 0;
      padding: 0 3rem;
    }

  .bannerSocial__figure:not(:first-child)::before {
    position: absolute;
    top: 10%;
    left: 0;
    width: 0.0625rem;
    height: 80%;
    background-color: rgba(0, 34, 28, .1);
    content: '';
  }
}

@media (min-width:1024px) {
  .bannerSocial__wrapper {
    flex-direction: row;
    max-width: 1024px;
      margin: 0 auto;
  }

  .bannerSocial__containerFigure {
    width: auto;
    flex: 1 0;
  }

  .bannerSocial__title {
    text-align: left;
  }
}