.stepElements {
  position: relative;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
  margin-block-end: 4.5rem;
}

.element {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex: 0 0 100%;
  max-width: 350px;
  min-height: 136px;
  margin-bottom: 2.25rem;
}

.element__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.element__icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.125rem;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  border: 0.125rem solid rgba(0, 34, 28, .1);
}

.element__icon::after {
  position: absolute;
  top: 7.6rem;
  left: 2.8rem;
  width: 1.625rem;
  height: calc(100% - 4.5rem);
  border-left: 0.125rem solid rgba(0, 34, 28, .1);
  content: "";
}

.element__icon--last::after {
  display: none;
}

.element__icon > img {
  width: 4rem;
  height: 4rem;
}

.element__content {
  flex: 1;
}

.element__title {
  margin-block-start: 0;
  margin-block-end: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.4;
  padding-bottom: 0.25rem;
  font-weight: 500;
}

.element__text {
  display: block;
  color: var(--textColorSecondary);
  font-size: 14px;
  line-height: 1.8;
}

@media (min-width: 1024px) {
  .stepElements {
    justify-content: space-evenly;
  }

  .element__icon::after {
    display: none;
  }
}