.headline {
  position: relative;
  min-height: 30vh;
  /* margin-block-end: 25px; */
}

.headline__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: inherit;
}

.headline__subtitle {
  margin-block-end: 15px;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  transform-style: preserve-3d;
  transform-origin: center top;
  margin-block-start: 0;
  margin-block-end: 0;
  text-transform: uppercase;
}

.headline__maintitle {
  font-size: 55px;
  line-height: 100%;
  letter-spacing: -.05em;
}

.maintitle {
  perspective: 600px;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin: 0;
}

@media (min-width:768px){
  .headline {
    /* min-height: 40vh; */
  }
}

@media (min-width:1200px){
  .headline__maintitle {
    font-size: 105px;
  }
}