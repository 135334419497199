.hamburguer {
  position: relative;
  width: 48px;
  height: 48px;
  background: transparent;
  outline: none;
  border: 0;
  padding: 12px 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  z-index: 1;
}

.hamburguer__line {
  width: 30px;
  background-color: var(--textColor);
  transition: background-color .5s ease-in-out, transform .35s ease-in-out;
  height: 2.5px;
  border-radius: 5px;
}

/* Animation */

.hamburguer--open .hamburguer__line:first-child {
  background-color: var(--textColor);
  transform: translateY(4px);
}

.hamburguer--open .hamburguer__line:last-child {
  background-color: var(--textColor);
  transform: translateY(-4px);
}