.parallax {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 20rem;
  /* background-image: url(/images/reyraf/parallax.jpg); */
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.parallax__title {
  color: white;
  padding: 5px 4rem;
  font-weight: 200;
  font-size: 2rem;
  text-align: center;
  margin: 0 0 15px;
  background-color: var(--mediumGreen);
  border-radius: 5px;
}

.parallax__link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  color: #fff;
  background-color: #008060;
  border-color: #008060;
  text-decoration: none;
}

.parallax__link:hover {
  color: #fff;
  background-color: #006d52;
  border-color: #00664d;
}

@media (min-width: 768px) {
  .parallax__title {
      font-size: 3rem;
    }
}

@media (min-width: 1024px) {
  .parallax {
    background-attachment: fixed;
  }
}