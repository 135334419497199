.headerAccount {
  width: 100%;
}

.headerAccount--mini {
  max-width: 48rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.headerAccount__wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: .75rem;
  padding-inline-end: .75rem;
}

.headerAccount__wrapper--mini {
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: 0rem;
  padding-inline-end: 0rem;
}

.headerAccount__body {
  border-bottom: 1px solid var(--lightGreen);
  padding-block-end: 1rem;
  padding-block-start: 1rem;
}

.headerAccount__body--mini {
  border-bottom: 0px;
}

.headerAccount__row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.headerAccount__subtitle {
  text-transform: uppercase;
  letter-spacing: .08rem;
  color: var(--greenHover);
  font-size: .75rem;
  margin-block-end: 0;
  font-weight: 500;
}

.headerAccount__title {
  margin-block-end: -10px;
  letter-spacing: -.02rem;
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--textColor);
  margin-block-start: 0.125rem;
}

.headerAccount__button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  padding: 0.5rem 0.5rem;
  font-size: .75rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.headerAccount__button:hover {
  background-color: var(--greenHover);
}
