.section__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-end: 3rem;
}

.section__title {
  margin-top: 0;
  margin-bottom: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 36px;
  text-align: center;
}

.section__title--green {
  color: var(--darkGreen);
  font-size: 42px;
}

.section__description {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 16px;
  color: var(--textColorSecondary);
  text-align: center;
  line-height: 1.9;
}

.section__description--important {
  font-size: 20px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .section__description {
    max-width: 768px;
  }
}