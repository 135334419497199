.callToAction {
  margin-block-end: 2rem;
  width: 100%;
}

.callToAction__card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  height: inherit;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0,34,28,.1);
  border-radius: 0.3125rem;
  background-color: var(--darkGreen);
  color: var(--whiteColor);
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  transition:all .2s ease-in-out;
  text-decoration: none;
  overflow: hidden;
}

.callToAction__card:focus, .callToAction__card:hover {
  -webkit-transform:translateY(-1rem);
  transform:translateY(-1rem) ;
}

.callToAction__body {
  padding: 2rem 2rem;
  flex: 1 1 auto;
}

.callToAction__label {
  margin-block-end: 1rem;
  margin-inline-end: 0.5rem;
  line-height: normal;
  background-color: rgba(249,250,252,.1);
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
}

.callToAction__title {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  font-size: 26px;
  font-weight: 400;
}

.callToAction__description {
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.8;
}

.callToActionLink {
  color: #f9fafc;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.callToAction__figure * {
  position: absolute;
  right: -25px;
  bottom: -40px;
  width: 15rem;
  color: var(--lightGreen);
  height: 15rem;
}

@media (min-width: 768px) {
  .callToAction {
    width: 282px;
    height: 350px;
  }
}

@media (min-width: 1024px) {
  .callToAction {
    width: 266px;
    height: 360px;
  }
}

@media (min-width: 1200px) {
  .callToAction {
    width: 312px;
    height: 360px;
  }
}

@media (min-width: 1280px) {
  .callToAction {
    width: 335px;
    height: 360px;
  }
}
