.cardBanner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0,34,28,.1);
  border-radius: 0.3125rem;
  border-width: 0;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  margin: 0 auto;
  max-width: 650px;
}

.cardBanner__body {
  padding: 1rem 1rem;
  background-color: rgba(0,189,142,.1);
  border-radius: 0.3125rem;
  flex: 1 1 auto;
}

.cardBanner__text {
  flex-grow: 1;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  color: var(--textColor);
}

.cardBanner__button {
  flex-shrink: 0;
  display: flex;
  gap: 0.5rem;
}

.cardBanner__button a {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  color: #fff;
  background-color: #008060;
  border-color: #008060;
  text-decoration: none;
}

.cardBanner__figure {
  display: flex;
  align-items: center;
}

.cardBanner__figure svg {
  width: 2rem;
  height: 2rem;
  color: var(--darkGreen);
}

.cardBanner__button a:hover {
  color:#fff;
  background-color:#006d52;
  border-color:#00664d;
}

@media (min-width: 768px) {
  .cardBanner__body {
    display: flex;
    align-items: center;
  }

  .cardBanner__figure {
    flex-shrink: 0;
    margin-inline-end: 1rem;
  }

  .cardBanner__text {
    margin-block-start: 0rem;
    margin-block-end: 0rem;
  }
}