.season {
  position: relative;
  max-width: 100%;
}

.season__head {
  margin-block-end: 3rem;
}

.season__title {
  font-size: 3rem;
  margin: 0;
  font-weight: 300;
}

.season__subtitle {
  font-size: 1.125rem;
}

.season__body {
  margin-block-end: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.season__row {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.season__primaryText {
  font-size: 1.75rem;
  color: rgb(0, 128, 96);
}

.season__icon {
  position: relative;
}

.season__icon > svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--lightGreen);
  font-size: 40rem;
}

@media (min-width: 768px) {
  .season__row {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    flex-direction: row;
    gap: 3rem;
  }

  .season__column {
    max-width: 60%;
  }

  .season__subtitle {
    max-width: 85%;
  }
}

@media (min-width: 820px) {
  .season__column {
    max-width: 45%;
  }
}

@media (min-width: 1024px) {
  .season__column {
    max-width: 35%;
  }

  .season__subtitle {
    max-width: 55%;
  }
}