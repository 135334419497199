.account {
  max-width: 48rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.cardAccount {
  border-width: 0;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0, 34, 28, .1);
  border-radius: 0.25rem;
  color: var(--textColorGray);
  z-index: 2;
  padding: 2rem;
}


.cardAccount__header {
  text-align: left;
  margin-bottom: 0;
  background-color: transparent;
}

.cardAccount__title {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--textColor);
}

.cardAccount__title--alert {
  color: var(--alertColorText);
}

.cardAccount__text {
  margin-bottom: 0;
  font-size: .875rem
}

.cardAccount__link {
  color: var(--green);
  font-weight: 500;
  font-size: .875rem
}

.cardAccount__body {
  flex: 1 1 auto;
  margin-block-start: 1rem;
}

.cardAccount__formGroup {
  margin-bottom: 1.5rem;
}

.cardAccount__formGroup--right {
  display: flex;
  justify-content: flex-end;
  gap: .5rem;
}

.cardAccount__label {
  font-size: .875rem;
  font-weight: 500;
  color: #51596C;
}

.cardAccount__label--discount {
  display: block;
}

.cardAccount__input {
  display: block;
  padding: .75rem 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(0, 34, 28, .4);
  appearance: none;
  border-radius: 0.3125rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-top: .5rem;
  width: 100%;
  box-sizing: border-box;
}

.cardAccount__input--discount {
  margin-bottom: 15px;
}

.cardAccount__input:focus {
  outline-color: var(--lightGreen);
}

.cardAccount__alert {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.cardAccount__alert--danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: var(--alertColorText);
}

.cardAccount__alert--success {
  background-color: var(--lightGreen);
  border-color:var(--lightGreen);
  color: var(--green);
}

.cardAccount__invalid {
  color: var(--alertColorText);
}

.cardAccount__inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardAccount__button{
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  padding: 0.5rem 1rem;
  font-size: .875rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  width: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.cardAccount__button:hover {
  background-color: var(--greenHover);
}

.cardAccount__button--alert{
  background-color: var(--alertColor);
  border: 0.0625rem solid var(--alertColor);
  color: var(--alertColorText);
}

.cardAccount__button--alert:hover {
  background-color: var(--alertColorHover);
}

.cardAccount__button--cancel {
  background-color: var(--grayColor);
  color: var(--textColor);
  border: 0.0625rem solid var(--textColorGray);
}

.cardAccount__button--cancel:hover {
  background-color: var(--whiteColor);
}

.cardAccount__shape {
  position: relative;
}

.cardAccount__shapeBotton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.cardAccount__shapeBotton > svg {
  width: 100%;
  height: auto;
  margin-bottom: -10px;
  transform-origin: top center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 1s linear infinite;
  margin-right: 10px;
}

.lineAccount {
  max-width: 48rem;
  border: 0;
  background-color: #e7eaf0;
  opacity: .9;
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
  height: 1px;
}

.cardAccount__listImages {
  display: flex;
  gap: 2rem;
}

.cardAccount__itemImage {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.cardAccount__img {
  display: block;
  max-height: 150px;
  margin-bottom: 0.5rem;
}

.cardAccount__imgRemove {

}

@media (min-width: 768px) {
  .cardAccount__input--discount {
    max-width: 250px;
    display: inline;
  }
  .checkoutStepTarget__formSubmit--discount {
    max-width: 200px;
    display: inline;
    margin-left: 15px;
  }
}

@media (min-width: 1400px) {
  .cardAccount__shape {
    margin-top: 12rem;
  }
}