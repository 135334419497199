.mainHero {
  position: relative;
  display: flex;
  height: calc(100vh - 3.5rem);
  overflow: hidden;
}

.mainHero__dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(19, 16, 34, 0.08) 0%, rgba(19, 16, 34, 0.28) 75%, rgba(19, 16, 34, 0.48) 100%);
  z-index: 1;
}

.mainHero__container {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  padding-bottom: 64px;
}

.mainHero__content {
  color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  z-index: 1;
}

.mainHero__wrapper {
  position: relative;
  max-width: 700px;
}

.mainHero__title {
  font-size: 5.5rem;
  margin: 0;
  margin-block-start: -30px;
  font-weight: 300;
}

.mainHero__text {
  font-size: 1.25rem;
  margin: 0;
  letter-spacing: 10px;
  text-transform: uppercase;
  max-width: 340px;
}

.mainHero__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 768px) {
  .mainHero__dark {
    background: linear-gradient(180deg, rgba(19, 16, 34, 0.08) 0%, rgba(19, 16, 34, 0.48) 70%, rgba(19, 16, 34, 0.78) 100%);
  }

  .mainHero__text {
    font-size: 2rem;
    max-width: 500px;
  }

  .mainHero__title {
    font-size: 7.5rem;
  }
}