.sidebarBurguer {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  pointer-events: none;
  width: 0;
  max-width: 100%;
  height: 100vh;
  background-color: var(--whiteColor);
  opacity: 0;
  visibility: hidden;
  transition: width .3s ease-in-out, visibility .3s ease-in-out, opacity .3s ease-in-out;
  transition-delay: .5s;
  z-index: 9;
}

.sidebarBurguer--open {
  pointer-events: all;
  width: 100vw;
  opacity: 1;
  visibility: visible;
  transition: width .3s ease-in-out, visibility 0.3s ease-in-out, opacity .3s ease-in-out;
}

.sidebarBurguer__menu {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding-inline-start: 2rem;
  padding-block-start: 8rem;
  box-sizing: border-box;
  list-style: none;

}

.list__item {
  margin-block-start: .5rem;
  opacity: 0;
  transition: all .5s ease-in-out;
}

.list__item:first-child {
  transition-delay: .3s;
}

.list__item:nth-child(2) {
  transition-delay: .4s;
}

.list__item:nth-child(3) {
  transition-delay: .5s;
}

.list__item:nth-child(4) {
  transition-delay: .6s;
}

.list__item:nth-child(5) {
  transition-delay: .7s;
}

.list__item:nth-child(6) {
  transition-delay: .8s;
}

.list__item:nth-child(7) {
  transition-delay: .9s;
}

.sidebarBurguer--open .list__item {
  opacity: 1;
  transform: translate3d(0, -2rem, 0);
}

.sidebarBurguer--close .list__item {
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

.sidebarBurguer--close .list__item:first-child {
  transition-delay: .1s;
}

.sidebarBurguer--close .list__item:nth-child(2) {
  transition-delay: .2s;
}

.sidebarBurguer--close .list__item:nth-child(3) {
  transition-delay: .3s;
}

.sidebarBurguer--close .list__item:nth-child(4) {
  transition-delay: .4s;
}

.list__link {
  position: relative;
  color: var(--textColor);
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 300;
  transition: all .4s;
}

.list__link::before {
  content: "";
  position: absolute;
  bottom: -1px;
  background-color: var(--textColor);
  height: 2px;
  transition: transform 0.3s;
  z-index: 20;
  left: 15%;
  width: 70%; /*calc(100% - 36px);*/
  transform: scaleX(0);
  will-change: transform;
}

.list__link:hover::before {
  transform: scaleX(1);
}

.list__link--close {
  opacity: .25;
  cursor: no-drop;
}

.list__link--close::before {
  display: none;
}

@media (min-width:768px) {
  .menu__list {
    padding-inline-start: 4rem;
  }
}