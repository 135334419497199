.header {
  position: sticky;
  background: var(--whiteColor);
  top: 0rem;
  z-index: 10;
  width: 100%;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
  border-bottom: 1px solid #f5f5f5;
  height: 3.5rem;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1800px;
  margin: auto;
  height: inherit;
  padding: 0 1rem;
}

.header__nav {
  flex: 1;
}

.header__logo {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  gap: 1rem;
}

.header__list--end {
  justify-content: flex-end;
}

.header__link {
  position: relative;
  color: var(--textColor);
  text-decoration: none;
  font-size: 1.25em;
  font-weight: 400;
  text-transform: uppercase;
  transition: all .4s;
  height: 48px;
  display: flex;
  align-items: center;
}

.header__link--close {
  opacity: .25;
  cursor: no-drop;
}

.logo {
  display: flex;
    align-items: center;
}

.logo img {
  width: 150px;
  z-index: 2;
}

.headerLink__phone {
  display: none;
}

.header__quantity {
  font-weight: 500;
  text-align: center;
  font-size: .7rem;
  letter-spacing: .04em;
  background: var(--green);
  color: #fff;
  position: absolute;
  right: -0.25rem;
  bottom: 0.25rem;
  width: 1.15rem;
  height: 1.15rem;
  line-height: 1.15rem;
  border-radius: 50%;
}

@media (min-width:820px) {
  .header__list {
    gap: 2rem;
  }
}

@media (min-width:1024px) {
  .headerLink__phone {
    display: block;
    font-size: .85rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
}