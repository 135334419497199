.login {
  max-width: 30rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.cardLogin {
  border-width: 0;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0, 34, 28, .1);
  border-radius: 0.25rem;
  color: var(--textColorGray);
  z-index: 2;
}


.cardLogin__header {
  padding-right: 2rem;
  padding-left: 2rem;
  text-align: center;
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
}

.cardLogin__title {
  margin-bottom: 0.5rem;
  font-size: 24px;
  font-weight: 500;
  color: var(--textColor);
}

.cardLogin__text {
  margin-bottom: 0;
  font-size: .875rem
}

.cardLogin__link {
  color: var(--green);
  font-weight: 500;
  font-size: .875rem
}

.cardLogin__body {
  padding: 2rem;
  flex: 1 1 auto;
}

.cardLogin__formGroup {
  margin-bottom: 1.5rem;
}

.cardLogin__label {
  font-size: .875rem;
  font-weight: 500;
  color: #51596C;
}

.cardLogin__input {
  display: block;
  padding: .75rem 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(0, 34, 28, .1);
  appearance: none;
  border-radius: 0.3125rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-top: .5rem;
  width: 100%;
  box-sizing: border-box;
}

.cardLogin__input:focus {
  outline-color: var(--lightGreen);
}

.cardLogin__invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: var(--alertColorText);
}

.cardLogin__invalidAlert {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.cardLogin__invalidSuccess {
  background-color: var(--lightGreen);
  border-color: var(--lightGreen);
  color: var(--green);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.cardLogin__inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardLogin__button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardLogin__button:hover {
  background-color: var(--greenHover);
}

.cardLogin__shape {
  position: relative;
}

.cardLogin__shapeBotton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.cardLogin__shapeBotton>svg {
  width: 100%;
  height: auto;
  margin-bottom: -10px;
  transform-origin: top center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 1s linear infinite;
  margin-right: 10px;
}

@media (min-width: 1400px) {
  .cardLogin__shape {
    margin-top: 12rem;
  }
}