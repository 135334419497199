.productDetail {
  position: relative;
  margin-block-end: 3rem;
}

.productDetail__iva {
  display: none;
}

.productDetail__gallery {
  position: relative;
}

.productDetail__galleryInner {
  width: 100%;
}

.productDetail__mainImage {
  position: relative;
  height: 65vh;
  width: auto;
  text-align: center;
  margin-block-end: 1.5rem;
  display: flex;
  justify-content: center;
}

.productDetail__dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(19, 16, 34, 0.01) 0%, rgba(19, 16, 34, 0.02) 80%, rgba(19, 16, 34, 0.05) 100%);
  z-index: 2;
  border-radius: inherit;
}

.productDetail__image {
  display: block;
  position: relative;
  width: 90%;
  height: auto;
  object-fit: contain;
}

.productDetail__mainImage .swiper-slide {
  display: flex;
  justify-content: center;
}

.productDetail__mainImage .swiper-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(19, 16, 34, 0.01) 0%, rgba(19, 16, 34, 0.02) 80%, rgba(19, 16, 34, 0.05) 100%);
  z-index: 2;
  border-radius: inherit;
}

.mySwiperThumb {
  display: flex;
  justify-content: flex-start;
  margin-left: unset !important;
}

.mySwiperThumb .swiper-slide {
  width: 80px !important;
}

.mySwiperThumb .swiper-slide-thumb-active {
  border-bottom: 5px solid var(--shinyGreen) !important;
}

.productDetail__thumbnails {
  overflow: hidden;
  padding-inline-start: 1rem;
}

.productDetail__thumbnailsInner {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.productDetail__thumbnailsItem {
  width: 80px;
}

.productDetail__thumbnailsLink {
  max-width: 100%;
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
}

.productDetail__thumbnail {
  width: 100%;
}

.productDetail__infoInner {
  padding: 1rem;
}

.productDetail__title {
  font-weight: 400;
  font-size: 2.5rem;
  margin-block-end: 0;
}

.productDetail__price {
  font-size: 1.75rem;
  font-weight: 300;
}

.productDetail__infoSuccess {
  margin-block-end: 1.5rem;
  margin-block-start: 1.5rem;
  display: flex;
  align-items: flex-start;
  vertical-align: top;
  line-height: 1.4;
  position: relative;
  padding: 15px 17px 14px;
  border-radius: 3px;
  background-color: #e0f4d5;
  color: rgb(57, 143, 8);
}

.productDetail__infoSuccess svg {
  width: 38px;
  height: 38px;
  margin: 0 16px 0 0;
  flex: 0 0 auto;
}

.productDetail__successText p {
  margin: 0;
}

.productDetail__quantityTitle {
  display: block;
  margin-block-end: .5rem;
}

.productDetail__quantityWrapper {
  display: flex;
  gap: 1rem;
  margin-block-end: 2rem;
}

.productDetail__quantityDownUp {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #c5c5c5;
  border-radius: 3px;
  height: 48px;
  width: 48px;
  min-width: 48px;
  color: #171717;
  font-size: inherit;
  box-sizing: border-box;
  line-height: 48px;
  background-color: #fff;
}

.productDetail__quantityTotal {
  text-align: center;
  width: 52px;
  padding: 0;
  height: 48px;
  margin-right: 10px;
  margin-left: 10px;
  flex-shrink: 1;
  border: 1.5px solid #c5c5c5;
  font-size: 16px;
  line-height: 14px;
  font-family: Muli,
  sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #ffffff;
  color: #171717;
  border: 1.5px solid #c5c5c5;
  padding: 0.8em 15px;
  margin: 0;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 3px;
  -webkit-appearance: none;
  box-sizing: border-box;
}

.productDetail__buttonCart {
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  color: #fff;
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: 1em;
  height: auto;
  margin: 0;
  text-decoration: none !important;
  cursor: pointer;
  padding: 15px 25px;
  vertical-align: middle;
  text-align: center;
  border-radius: 3px;
  transition: background-color .1s,
  color .1s,
  border-color .1s,
  opacity .1s;
  display: inline-block;
  font-family: Muli,
  sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
}

.productDetail__buttonCart:hover {
  background: #07dc54;
  border-color: #07dc54
}

.productDetail__description {
  letter-spacing: 1px;
  line-height: 2;
  font-size: 1.25rem;
  margin-block-start: 2rem;
  margin-block-end: 2rem;
}

.productDetail__features {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.productDetail__feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productDetail__featureTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: var(--green);
}

.productDetail__featureTitle span {
  font-size: 1rem;
}

.productDetail__featureDescription {
  color: var(--green);
}

.productDetail__notice {
  margin-block-end: 1.5rem;
  margin-block-start: 1.5rem;
  display: block;
  line-height: 1.4;
  position: relative;
  padding: 15px 17px 14px;
  border-radius: 3px;
  background-color: #f5e3e0;
  color: rgb(147, 35, 16);
  font-size: 14px;
}

.productDetail__notice a {
  color: inherit;
  font-weight: 600;
}

.productDetail__notStock {
  margin-block-end: 1.5rem;
  margin-block-start: 1.5rem;
  display: block;
  line-height: 1.4;
  position: relative;
  padding: 15px 17px 14px;
  border-radius: 3px;
  background-color: rgb(147, 35, 16);
  color: #f5e3e0;
  font-size: 14px;
}

@media (min-width: 768px) {
  .productDetail {
    display: flex;
    max-width: 1480px;
    margin: 0 auto;
  }

  .productDetail__gallery {
    flex: 0 0 auto;
    width: 50%;
    padding: 1rem;
    margin-block-start: 3rem;
    box-sizing: border-box;
  }

  .productDetail__info {
    flex: 0 0 auto;
    width: 50%;
    box-sizing: border-box;
  }

  .productDetail__features {
    justify-content: flex-start;
  }

  .productDetail__feature:first {
    margin-right: 3rem;
  }
}