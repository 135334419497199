.alice-carousel__stage-item {
  margin-inline-end: 1rem;
}

.alice-carousel__stage-item * {
  line-height: initial;
  height: 200px;
}

.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50rem;
  background-color: var(--lightGreen);
  transition: background-color 0.75s ease-in-out, width 0.5s ease-in-out;
}

.alice-carousel__dots-item:not(.__custom):hover {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50rem;
  background-color: var(--green);
}

.alice-carousel__dots-item:not(.__custom).__active {
  background-color: var(--green);
  width: 24px;
  border-radius: 50rem;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  color: var(--mediumGreen);
  transition: color 0.75s ease-in-out;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: var(--green);
}