.cardAccountInline {
  background-color: #fff;
  border: 0 solid #eceef3;
  border-radius: 0.75rem;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  border: 0.0625rem solid rgba(0, 34, 28, .1);
  position: relative;
  width: auto;
  margin: 1rem 0;
}

.cardAccountInline__wrapper {
  padding: 1.25rem;
}

.cardAccountInline__header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  align-items: baseline;
}

.cardAccountInline__col, .cardAccountInline__colActions {
  display: flex;
  align-items: center;
}

.cardAccountInline__colActions {
  gap: .5rem;
}

.cardAccountInline__icon {
  display: flex;
  font-size: 1.5rem;
  background-color: var(--greenHover);
  color: var(--whiteColor);
  border-radius: 50%;
  padding: 1rem;
}

.cardAccountInline__img {
  display: flex;
  padding: 0;
}

.cardAccountInline__badge {
  background: var(--greenHover);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 10%;
  margin-inline-start: 0.5rem;
}

.cardAccountInline__badge--danger {
  background: var(--alertColorText);
}

.cardAccountInline__badge--warning {
  background: var(--warningColor);
  color: var(--warningColorText);
}

.cardAccountInline__img img {
  width: 80px;
  height: auto;
}

.cardAccountInline__info {
  margin-inline-start: 1.5rem;
}

.cardAccountInline__title {
  margin-block-end: 0.25rem;
  font-size: 1.25rem;
  color: var(--textColor);
  line-height: 1.3;
  margin-block-start: 0;
  font-weight: 500;
}

.cardAccountInline__text {
  color: var(--textColorGray);
  font-size: .875rem;
  display: block;
}

.cardAccountInline__button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  width: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.cardAccountInline__button:hover {
  background-color: var(--greenHover);
}

.cardAccountInline__button--mini {
  padding: 0.5rem .5rem;
    font-size: .825rem;
}

.cardAccountInline__button--success {
  background-color: var(--greenHover);
  border: 0.0625rem solid var(--greenHover);
  color: var(--whiteColor);
}

.cardAccountInline__button--success:hover {
  background-color: var(--shinyGreen);
}

.cardAccountInline__button--info {
  background-color: var(--infoColor);
  border: 0.0625rem solid var(--infoColor);
  color: var(--infoColorText);
}

.cardAccountInline__button--info:hover {
  background-color: var(--infoColorHover);
}

.cardAccountInline__button--warning {
  background-color: var(--warningColor);
  border: 0.0625rem solid var(--warningColor);
  color: var(--warningColorText);
}

.cardAccountInline__button--warning:hover {
  background-color: var(--warningColorHover);
}

.cardAccountInline__button--danger {
  background-color: var(--alertColor);
  border: 0.0625rem solid var(--alertColor);
  color: var(--alertColorText);
}

.cardAccountInline__button--danger:hover {
  background-color: var(--alertColorHover);
}

.cardAccountInline__hr {
  border: 0;
  background: #e7eaf0;
  margin: 1.25rem 0 0;
  opacity: .9;
  height: 1px;
}

.cardAccountInline__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  border-bottom: 1px solid #e7eaf0;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width:768px) {
  .cardAccountInline__row {
    flex-direction: row;
    gap: 0rem;
  }
  .cardAccountInline__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 0rem;
  }
}