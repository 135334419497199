.cards__scroll {
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  padding-block-end: 3rem;
  margin-block-end: 1.5rem;
}

.cards {
  display: flex;
  justify-content: center;
}

.cards__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1170px;
}

.cards .card {
  max-width: 100%;
}

.card {
  flex: 0 0 auto;
  min-width: 265px;
  margin-bottom: 3rem;
  max-width: 25%;
}

.card__inner {
  position: relative;
}

.card__link {
  position: relative;
  display: block;
  text-decoration: none;
  color: inherit;
  transition: color .1s,
  border-color .1s,
  opacity .1s,
  text-decoration-color .1s;
}

.card__image {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.card__dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(19, 16, 34, 0.01) 0%, rgba(19, 16, 34, 0.02) 80%, rgba(19, 16, 34, 0.05) 100%);
  z-index: 1;
  border-radius: inherit;
}

.card__swapImage {
  position: relative;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__picture {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  max-width: 90%;
  vertical-align: middle;
  height: auto;
}

.card__badge {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  display: inline-flex;
  gap: 0.25rem;
}

.cardBadge__featured {
  display: inline-flex;
  font-size: 1.25em;
  line-height: 1;
  color: #fff;
  border-radius: 5px;
  background-color: #f5cf05;
  height: 29px;
  width: 29px;
  justify-content: center;
  align-items: center;
}

.cardBadge__new {
  padding: 0.5em 0.7em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  background-color: var(--shinyGreen);
}

.cardBadge__blocked {
  padding: 0.5em 0.7em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  background-color: var(--alertColorText);
}

.card__cart {
  display: block;
  background: rgba(171, 75, 138, 0);
  cursor: pointer;
  clear: both;
  pointer-events: all;
  height: 35px;
  width: 35px;
  z-index: 2;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 24px;
  color: var(--shinyGreen);
}

.card__info {
  display: block;
  color: inherit;
  margin: 10px 0 0;
  font-size: 14px;
  text-align: left;
}

.card__title {
  margin: 0;
  font-weight: 300;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .cards__inner {
    gap: 2rem;
  }

  .cards .card {
    max-width: 47%;
  }
}

@media (min-width: 1024px) {
  .cards .card {
    max-width: 30%;
  }
}

@media (min-width: 1280px) {
  .cards__inner {
    gap: 2.25rem;
  }

  .cards .card {
    max-width: 22%;
  }
}