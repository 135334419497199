.policyHero {
  position: relative;
  background-color: #005c45;
  overflow: hidden;
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.policyHero__title {
  position: relative;
  color: white;
  font-size: 44px;
  font-weight: 300;
  z-index: 1;
}

.policyHero__absolute {
  position: absolute;
}

.policyHero__absolute svg {
  font-size: 15rem;
  color: var(--green);
  opacity: .25;
}

.policyHero__absolute--left {
  top: -7rem;
  left: 0rem;
}

.policyHero__absolute--right {
  bottom: -2rem;
  right: -5rem;
}