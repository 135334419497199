.cartTotal__wrapper {
  padding: 50px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: right;
}



.cartTotal__text {
  margin: 0 0 30px;
  font-size: 22px;
  color: #171717;
  line-height: 1.2;
}

.cartTotal__checkout {
  margin-bottom: 10px;
}

.cartTotal__button {
  font-size: 14px;
  text-decoration: none !important;
  cursor: pointer;
  min-width: 200px;
  max-width: 250px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}

.cartTotal__button:hover {
  background-color: var(--greenHover);
}

.cartTotal__continue {
  display: block;
  color: var(--green);
  text-decoration: none;
  margin-block-start: 16px;
  font-size: .85rem;
}