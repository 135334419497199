.heroMosaic {
  position: relative;
  width: 100%;
  /* padding-inline-start: 12px;
  padding-inline-end: 12px; */
  margin: 0;
}

.heroMosaic__row {
  position: relative;
  display: flex;
  justify-content: center;
  margin-block-end: .5rem;
}

.heroMosaic__row--end {
  margin-block-start: 0;
  align-items: flex-end;
}

.heroMosaic__cell {
  width: 45%;
  max-width: 100%;
  margin-block-start: 0;
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

.heroMosaic__cell--absolute {
  /* position: absolute; */
  position: relative;
  right: 0px;
  top: 35px;
}

.heroMosaic__cell--hidden {
  display: none;
}

.heroMosaic__image {
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media (min-width: 820px) {
  .heroMosaic__cell--hidden {
    display: block;
    position: absolute;
    top: 50%;
  }

  .heroMosaic__cell--hidden--first {
    left: 0;
  }

  .heroMosaic__cell--hidden--last {
    right: 0;
  }

  .heroMosaic__cell {
    width: 24%;
  }

  /* .heroMosaic__cell--left {
    margin-left: 25%;
  } */

  .heroMosaic__cell--absolute {
    position: relative;
    top: 0;
    right: 0;
  }
}