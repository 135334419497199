.checkout__summaryMobile {
  flex: 0 1 auto;
  margin-block-end: 0;
  height: 60px;
  background: var(--lightGreen);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 3;
  cursor: pointer;
}

.checkout__summaryMobilIcon {
  font-size: 20px;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout__summaryMobileTitle {
  flex: 2;
  padding-inline-start: 15px;
}

.checkout__summaryMobilePrice {
  flex: 1;
  font-weight: 600;
  text-align: right;
  padding-inline-end: 10px;
}

.checkout__summaryMobileShow {
  flex: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.checkout__summary {
  display: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  right: 0;
  background: #fff;
  z-index: 3;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-gap: 20px;
  height: 100%;
}

.checkout__summaryDetail {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: visibility .2s ease-in-out, opacity .2s ease-in-out,
    height .5s ease-in-out;
}

.checkout__summaryDetail--active {
  opacity: 1;
  height: auto;
  visibility: visible;
  transition: visibility .2s ease-in-out, opacity .2s ease-in-out,
    height .5s ease-in-out;
}

.checkout__summaryDetailInner {
  padding: 1rem;
}