.blockquote {
  padding: 0 12px;
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  box-sizing: border-box;
}

.blockquote__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 0;
  margin-inline-start: -12px;
  margin-inline-end: -12px;
  box-sizing: border-box;
}

.blockquote__link {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-block-end: 4.5rem;
  padding-inline-end: 12px;
  padding-inline-start: 12px;
  box-sizing: border-box;
}

.blockquoteLink__banner {
  position: relative;
  box-sizing: border-box;
}

.blockquoteLink__banner::after {
  position: absolute;
  bottom: -3rem;
  left: -3rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 189, 142, .1);
  content: '';
}

.blockquote__image {
  position: relative;
  z-index: 1;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

.blockquoteBanner__caption {
  position: absolute;
  padding: 2rem;
  z-index: 1;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.blockquoteBanner__icon {
  flex-shrink: 0;
}

.blockquoteIcon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  width: 2.875rem;
  height: 2.875rem;
  padding: 0;
  background-color: #fff;
  border-color: rgba(0, 34, 28, .1);
  border-radius: 5px;
}

.blockquoteIcon>svg {
  color: rgb(0, 128, 96);
  font-size: 1.75rem;
}

.blockquoteBanner__title {
  color: white;
  margin-inline-start: 8px;
  text-shadow: 1px 1px 10px black;
}

.blockquote__text {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  /* margin-block-end: 4.5rem; */
  padding-inline-end: 12px;
  padding-inline-start: 0px;
  box-sizing: border-box;
}

.blockquoteText__cite {
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
}

.blockquoteCite {
  color: #00221c;
  font-size: 1.25rem;
  margin-block-end: 0;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
  line-height: 2rem;
  font-style: italic;
}

.blockquoteCite__footer {
  font-weight: 500;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #00221c;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
}

.blockquoteCiteFooter__source {
  display: block;
  color: #677788;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.blockquoteText__avatar {
  width: 2.5rem;
  height: auto;
  border-radius: 0;
  margin-inline-start: 6px;
  margin-block-start: 1rem;
}

@media (min-width: 768px) {
  .blockquote {
    padding-block-start: 0rem;
    padding-block-end: 7.5rem;
    max-width: 720px;
  }

  .blockquote__wrapper {
    align-items: center;
  }

  .blockquote__text {
    flex: 0 0 auto;
    width: 50%;
    margin-block-end: 0;
  }

  .blockquote__link {
    flex: 0 0 auto;
    width: 50%;
    order: 2;
    margin-block-end: 0;
  }

  .blockquoteText__blockquote {
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0;
  }

  .blockquoteLink__banner::after {
    bottom: -2rem;
    left: -2rem;
  }
}

@media (min-width: 992px) {
  .blockquote {
    max-width: 960px;
  }

  .blockquoteLink__banner {
    max-width: 450px;
    float: right;
  }
}

@media (min-width: 1200px) {
  .blockquote {
    max-width: 1140px;
  }
}