.footer {
  background-color: var(--darkGreen);
  color: var(--whiteColor);
  border-top: .0625rem solid rgba(0,34,28,.1);
}

.footer__main {
  padding-block-start: 4rem;
  padding-block-end: 4rem;
}

.footer__mainContent {
  display: flex;
  flex-wrap: wrap;
}

.footer__column {
  flex: 100%;
  margin-block-end: 3rem;
}

.footer__title {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: .03125rem;
  text-transform: uppercase;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  color: var(--shinyGreen);
}

.footer__text {
  font-size: .85rem;
  font-weight: 400;
  line-height: 2rem;
  margin-block-end: 10px;
  color: var(--whiteColor);
}

.footer__groupText {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer__menu {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

.footer__list {
  margin-block-end: 10px;
}

.footer__link {
  position: relative;
  color: var(--grayColor);
  text-decoration: none;
  font-size: .85rem;
}

.footer__link--close {
  cursor: no-drop;
  /* color: var(--green); */
  opacity: .25;
}

.footer__link::before {
  content: "";
  position: absolute;
  bottom: -3px;
  background-color: var(--grayColor);
  height: 1px;
  transition: transform 0.3s;
  z-index: 20;
  width: 100%; /*calc(100% - 36px);*/
  transform: scaleX(0);
  will-change: transform;
}

.footer__link:hover::before {
  transform: scaleX(1);
}

.footer__link--close::before {
  display: none;
}

.footer__line {
  border-top: 0.0625rem solid rgba(0,34,28,.1);
  border-color: rgba(255,255,255,.1);
}

.footer__legal {
  padding-block-start: 2rem;
  padding-block-end: 2rem;
}

.footer__legalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

.footer__legalText {
  position: relative;
  color: inherit;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
}

.footer__legalCopy {
  position: relative;
  font-size: 11px;
  line-height: 22px;
  text-decoration: none;
}

.bannerFooter {
  text-align: center;
  box-sizing: border-box;
}

.bannerFooter__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.bannerFooter__figure {
  flex: 1 0 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}



.bannerFooter__figure:last-child {
  border-right: none;
}

.bannerFooter__img,
.bannerFooter__img--little {
  width: 9rem;
  height: auto;
  border-radius: 0;
}

.footer__cards {
  height: 15px;
  width: auto;
  margin-right: 5px;
  background-color: white;
}

@media (min-width:768px) {
  .footer__mainContent {
    gap: 1rem;
  }
  .footer__column {
    flex: 1 0;
    margin-block-end: 0;
  }

  .footer__column:first-child {
    flex-wrap: wrap;
    flex: 100% 1;
  }

  .footer__legalContent {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__legalText {
    flex-direction: row;
    text-align: left;
    gap: 1rem;
  }

  .footer__legalCopy {
    text-align: right;
  }

  .bannerFooter__img,
  .bannerFooter__img--little {
    width: 8rem;
  }

  .bannerFooter__figure:not(:first-child)::before {
    position: absolute;
    top: 10%;
    left: 0;
    width: 0.0625rem;
    height: 80%;
    background-color: rgba(0, 34, 28, .1);
    content: '';
  }
}

@media (min-width: 1024px) {
  .footer__column {
    flex: 1 0;
  }
  .footer__column:first-child {
    flex: 0 0 auto;
    flex-wrap: nowrap;
    width: 25%;
    padding: 3rem;
    padding-block-start: 0;
  }

  .bannerFooter__img--little {
    width: 7rem;
  }

  .bannerFooter__img {
    width: 9rem;
  }
}
