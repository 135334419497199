.heroImage {
  position: relative;
}

.heroImage__content {
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 56.25%;
  -webkit-perspective: 100px;
  -moz-perspective: 100px;
  perspective: 100px;
  /* padding-block-end: 100%; */
}

.heroImage__content:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
}

.heroImage__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}