.infoSection {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.infoSection::after {
  height: 95%;
  background: linear-gradient(to right,rgba(255,255,255,0) 0,rgba(0,189,142,.1) 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: block;
  content: '';
}

.infoSection__content {
  padding: 1.5rem;
  width: 100%;
  max-width: 100%;
  padding-block-end: 0;
  z-index: 1;
}

.infoSection__head {
  margin-block-end: 2rem;
}

.infoSection__subtitle {
  display: block;
  color: #00221c;
  font-size: .75rem;
  font-weight: 500;
  letter-spacing: .03125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.infoSection__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #00221c;
}

.infoSection__description {
  margin-top: 0;
  margin-bottom: 1rem;
}

.infoSection__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.infoSection__feature {
  display: flex;
  align-items: flex-start;
}

.infoSection__featureIcon {
  flex-shrink: 0;
}

.infoSection__featureIcon > svg {
  color: rgba(0,128,96);
  font-size: 1.25rem;
  margin-block-start: 5px;
}

.infoSection__featureContent {
  flex-grow: 1;
  margin-inline-start: 0.75rem;
}

.infoSection__featureTitle, .infoSection__featureTitle a {
  position: relative;
  color: var(--green);
  font-size: 1.25rem;
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
}

.infoSection__featureTitle a::before {
  content: "";
  position: absolute;
  bottom: -3px;
  background-color: var(--green);
  height: 1px;
  transition: transform 0.5s;
  z-index: 20;
  width: 100%; /*calc(100% - 36px);*/
  transform: scaleX(0);
  will-change: transform;
}

.infoSection__featureTitle a:hover::before {
  transform: scaleX(1);
}

.infoSection__featureDescription {
  /*color: rgb(81,89,108);*/
}

.infoSection__contentImage {
  width: 100%;
  max-width: 100%;
  margin-block-start: 0;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  z-index: 1;
}

.infoSection__image {
  width: 95%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  text-align: center;
}

.infoSection__image > img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .infoSection {
    flex-wrap: nowrap;
    align-items: center;
  }

  .infoSection::after {
    height: 100%;
    background: linear-gradient(to left,rgba(255,255,255,0) 22%,rgba(0,189,142,.1) 22%);
  }

  .infoSection__content {
    padding: 8rem 4rem;
  }

  .infoSection__contentImage {
    padding-block-start: 4rem;
    padding-block-end: 4rem;
  }

  .infoSection__image {
    width: 100%;
  }
}