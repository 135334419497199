.alice-carousel__stage-item * {
  line-height: initial;
  height: 150px;
}

.alice-carousel__stage-item {
  width: 250px
}

@media (min-width: 768px) {
  .alice-carousel__stage-item * {
    line-height: initial;
    height: 300px;
  }

  .alice-carousel__stage-item {
    width: 500px
  }
}