.backToTop {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  width: 2.875rem;
  height: 2.875rem;
  background-color: rgba(0,189,142,.1);
  color: #008060;
  font-size: 1rem;
  opacity: .5;
  border-radius: 0.3125rem;
  transition: .6s ease-out;
  right: 2rem;
  bottom: 2rem;
  cursor: pointer;
  position: fixed;
  border: none;
}

.backToTop:hover {
  color:#fff;
  background-color:#008060;
  opacity:1;
}
