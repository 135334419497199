.checkout {
  display: flex;
  box-sizing: border-box;
}

.checkout__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 24px;
}

.checkout__content {
  flex: 1;
  width: 100%;
  align-self: center;
}



.checkoutStep {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 20px;
}

.checkoutStep__content {
  display: none;
}

.checkoutStep__open .checkoutStep__content {
  display: block;
}

.checkoutStep__main {
  display: flex;
  flex-direction: column;
}

.checkStep__content {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin-block-end: 0;
}

.checkoutStep__col {
  flex: 1;
}

.checkoutStep__col--right {
  flex: 0 1 auto;
}

.checkoutStep__title {
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;
  color: #cecece;
}

.checkoutStep__open .checkoutStep__title {
  color: #333;
}

.checkoutStep__description {
  margin-inline-start: 16px;
  color: var(--green);
  margin-block-end: 1rem;
  display: flex;
  gap: 0.5rem;
  font-size: 20px;
}

.checkoutStep__description svg {
  color: white;
  font-size: 18px;
  background-color: var(--green);
  border-radius: 50%;
  padding: 0.125rem;
}

.checkoutStep__action {
  color: #cecece;
  font-size: 12px;
  cursor: pointer;
  display: block; /*none*/
  transition: all .3s ease-in-out;
}

.checkoutStep__action:hover {
  color: var(--textColorGray);
  text-decoration: underline;
}

.checkoutStep__action--active {
  display: block;
}

.checkoutStep__keypad {
  display: flex;
  flex: 0;
  margin-block-end: 25px;
  gap: 1rem;
  flex-direction: column;
}

.checkoutStep__keypad--column {
  flex-direction: column;
}

.checkoutStep__check {
  margin: 1rem 0;
}

.checkoutStep__check a {
  color: var(--green);
}

.checkoutStep__check a:hover {
  color: var(--greenHover);
}

.checkoutStep__button {
  flex: 1;
}

.checkoutStepButton {
  border: 1px solid #e0e0e0;
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  font-family: var(--baseFontFamily);
}

.checkoutStepButton--large {
  flex-direction: row;
}

.checkoutStepButton--selected {
  background-color: var(--sectionBackgroundColor);
    border: 1px solid var(--shinyGreen);
}

.checkoutStepButton__icon {
  font-size: 24px;
}

.checkoutStepButton.checkoutStepButton--large .checkoutStepButton__icon {
  flex: .08;
}

.checkoutStepButton__text {
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
}

.checkoutStepButton.checkoutStepButton--large .checkoutStepButton__text {
  flex: 1;
  text-align: left;
  padding: 10px;
}

.checkoutStep__targets {
  margin-block-end: 20px;
}

.checkoutStepTarget {
  display: block; /*none*/
}

.checkoutStepTarget--selected {
  display: block;
}

.checkoutStepTarget__text {
  font-weight: 300;
  font-size: 1.125rem;
}

.checkoutStepTarget__formColumn {
  margin-block-end: 10px;
}

.checkoutStepTarget__formGroup {
  position: relative;
  margin-block-end: 20px;
}

.checkoutStepTarget__formLabel {
  position: absolute;
  left: 16px;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  display: inline-block;
  color: #cecece;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  transition: transform .25s;
}

.checkoutStepTarget__formLabel.activeLabel {
  transform: scale(.8) translate(-13%, -107%);
}

.checkoutStepTarget__formInput {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  height: 44px;
  border: 1px solid #b2b2b2;
  padding: 16px 24px 0 16px;
  margin: 0;
  border-radius: 0;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
}

.checkoutStepTarget__formInput:focus {
  outline: none;
}

.checkoutStepTarget__formLink {
  color: #979797;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline;
}

.checkoutStepTarget__formSubmit {
  position: relative;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--green);
  border: 0.0625rem solid var(--green);
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkoutStepTarget__formSubmit:hover {
  background-color: var(--greenHover);
}

.checkoutStepTarget__formSubmit:hover:disabled {
  background-color: var(--green);
}

.checkoutStepTarget__formSubmit:disabled, .checkoutStepTarget__formSubmit[disabled] {
  cursor: not-allowed;
  opacity: .65;
}

.paypal {
  display: flex;
  justify-content: center;
}

.paypal>div {
  min-width: 335px;
}

@media (min-width:768px) {
  .checkout__wrapper {
    margin: 3rem auto;
    padding: 0 100px;
  }

  .checkoutStep__keypad {
    flex-direction: row;
  }

  .paypal > div {
    min-width: 580px;
  }
}

@media (min-width:1024px) {
  .checkout__wrapper {
    padding: 0 80px;
  }

  .paypal>div {
    min-width: 768px;
  }
}

@media (min-width:1200px) {
  .checkout__wrapper {
    max-width: 1280px;
    padding: 0 55px;
  }
}

@media (min-width:1400px) {
  .checkout__wrapper {
    padding: 0;
  }
}