.prize {
  overflow: hidden;
}

.prize__row {
  display: flex;
  flex-wrap: wrap;
}

.prize__column {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.prize__figure {
  position: relative;
  z-index: 1;
  max-width: 158px;
  margin: 0 auto 3rem;
}

.prize__img {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%), 0 2rem 4rem -2rem rgb(0 34 28 / 30%), inset 0 -0.1875rem 0.3125rem 0 rgb(151 164 175 / 50%);
  border-radius: 2rem;
  padding: 0.3125rem;
  width: 158px;
  max-width: 158px;
  margin: 0 auto;
  z-index: 1;
}

.prize__img img {
  max-width: 100%;
  height: auto;
  border-radius: 1.6rem;
}

.prize__icon {
  position: absolute;
  top: 25%;
  right: -6rem;
  color: var(--lightGreen);
  font-size: 11rem;
  z-index: -1;
}

.prize__head {
  margin-block-end: 1.5rem;
}

.prize__title {
  font-weight: 500;
  line-height: 1.2;
  font-size: 36px;
}

.prize__text {
  font-size: 16px;
  color: var(--textColorSecondary);
  line-height: 1.9;
}

.prize__listCheck {
  padding-left: 0;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
}

.prize__check {
  margin-block-end: .5rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-inline-start: .5rem;
}

.prize__check svg {
  color: var(--green);
  font-size: 1.5rem;
}

@media (min-width: 820px) {
  .prize__row {
    align-items: center;
  }
  .prize__column {
    flex: 0 0 auto;
    width: 50%;
  }
  .prize__column--order {
    order: 2;
  }
}

@media (min-width: 820px) {
  .prize__row {
    padding: 0 7rem;
  }
}