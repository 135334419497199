.cartItem {
  display: flex;
  border-top: 0;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 30px;
  padding-bottom: 30px;
}

.productDetail__iva {
  display: none;
}

.cartItem__columnImage {
  width: 80px;
  padding-right: 20px;
  min-height: 80px;
  height: auto;
}

.cartItem__link {
  position: relative;
  display: block;
  height: inherit;
}

.cartItem__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  object-fit: contain;
}

.cartItem__column {
  display: block;
  flex-grow: 1;
}

.cartItem__title {
  text-align: left;
  width: auto;
  padding: 0;
  flex: 1 1;
}

.cartItem__linkTitle {
  color: var(--green);
  text-decoration: none;
}

.cartItem__pice {
  flex-shrink: 0;
  text-align: left;
  width: auto;
  padding: 0;
}

.cartItem__price span {
  white-space: nowrap;
}

.cartItem__quantity {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  text-align: left;
  width: auto;
  padding: 0;
  flex-shrink: 0;
}

.cartItem__quantityTitle {
  display: block;
  margin-block-end: .5rem;
}

.cartItem__quantityWrapper {
  display: flex;
  gap: 1rem;
  margin-block-end: 1rem;
}

.cartItem__quantityDownUp {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #c5c5c5;
  border-radius: 3px;
  height: 48px;
  width: 48px;
  min-width: 48px;
  color: #171717;
  font-size: inherit;
  box-sizing: border-box;
  line-height: 48px;
  background-color: #fff;
}

.cartItem__quantityTotal {
  text-align: center;
  width: 48px;
  padding: 0;
  height: 48px;
  margin-right: 10px;
  margin-left: 10px;
  flex-shrink: 1;
  border: 1.5px solid #c5c5c5;
  font-size: 16px;
  line-height: 14px;
  font-family: Muli,
    sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #ffffff;
  color: #171717;
  border: 1.5px solid #c5c5c5;
  padding: 0.8em 15px;
  margin: 0;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 3px;
  -webkit-appearance: none;
  box-sizing: border-box;
}

.cartItem__remove {
  display: block;
  color: var(--green);
  text-decoration: none;
}

.cartItem__total {
  text-align: right;
  width: auto;
  padding: 0;
  flex-shrink: 0;
}

.cartItem__total span {
  white-space: nowrap;
  margin-left: 5px;
  font-weight: 600;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .cartItem__columnImage {
    width: 120px;
  }

  .cartItem__info {
    display: flex;
    justify-content: space-between;
  }

  .cartItem__quantity {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;
  }

  .cartItem__total {
    margin-block-start: 1rem;
  }
}