.video {
  padding: 0 12px;
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  box-sizing: border-box;
}

.video__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 0;
  margin-inline-start: -12px;
  margin-inline-end: -12px;
  box-sizing: border-box;
}

.video__link {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-block-end: 4.5rem;
  padding-inline-end: 12px;
  padding-inline-start: 12px;
  box-sizing: border-box;
}

.videoLink__banner {
  position: relative;
  box-sizing: border-box;
}

.videoLink__banner::after {
  position: absolute;
  bottom: -3rem;
  left: -3rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 189, 142, .1);
  content: '';
}

.video__image {
  position: relative;
  z-index: 1;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

.videoBanner__caption {
  position: absolute;
  padding: 2rem;
  z-index: 1;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.videoBanner__icon {
  flex-shrink: 0;
}

.videoIcon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  width: 2.875rem;
  height: 2.875rem;
  padding: 0;
  background-color: #fff;
  border-color: rgba(0, 34, 28, .1);
  border-radius: 5px;
}

.videoIcon > svg {
  color: rgb(0, 128, 96);
  font-size: 1.75rem;
}

.videoBanner__title {
  color: white;
  margin-inline-start: 8px;
  text-shadow: 1px 1px 10px black;
  background: var(--mediumGreen);
  padding: 0 0.5rem;
  border-radius: 5px;
}

.video__text {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  /* margin-block-end: 4.5rem; */
  padding-inline-end: 12px;
  padding-inline-start: 12px;
  box-sizing: border-box;
}

.videoText__blockquote {
  margin-block-end: 3rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
}

.videoBlockquote {
  color: #00221c;
  font-size: 1.5rem;
  margin-block-end: 0;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
}

.videoBlockquote__footer {
  font-weight: 500;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #00221c;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
}

.videoBlockquoteFooter__source {
  display: block;
  color: #677788;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.videoText__avatar {
  width: 5rem;
  height: auto;
  border-radius: 0;
  margin-inline-start: 1rem;
  margin-block-start: 1.5rem;
}

@media (min-width: 768px) {
  .video {
    padding-block-start: 0rem;
    padding-block-end: 0rem;
    max-width: 720px;
  }

  .video__wrapper {
    align-items: center;
  }

  .video__text {
    flex: 0 0 auto;
    width: 50%;
    margin-block-end: 0;
  }

  .video__link {
    flex: 0 0 auto;
    width: 50%;
    order: 2;
    margin-block-end: 0;
  }

  .videoText__blockquote {
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0;
  }

  .videoLink__banner::after {
    bottom: -2rem;
    left: -2rem;
  }
}

@media (min-width: 992px) {
  .video {
    max-width: 960px;
  }

  .videoLink__banner {
    max-width: 450px;
    float: right;
  }
}

@media (min-width: 1200px) {
  .video {
    max-width: 1140px;
  }
}


/* Modal video */

@keyframes modal-video {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px)
  }

  to {
    transform: translate(0, 0)
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  -ms-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.modal-video-effect-exit {
  opacity: 0
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px)
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  -ms-transition: -ms-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px
  }
}

.modal-video-close-btn:before {
  transform: rotate(45deg)
}

.modal-video-close-btn:after {
  transform: rotate(-45deg)
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px
}