.feature {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-block-end: 2.5rem;
  flex-wrap: wrap;
}

.feature__icon {
  background-color: var(--lightGreen);
  color: var(--green);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  margin-inline-start: 0;
  margin-block-start: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.feature__icon img {
  width: 5rem;
  height: 5rem;
}

.feature__image img {
  width: 80px;
}

.feature__text {
}

.feature__title {
  margin-block-start: 0;
  margin-block-end: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.4;
  padding-bottom: 0.25rem;
  font-weight: 500;
}

.feature__description {
  color: var(--textColorSecondary);
  font-size: 14px;
  line-height: 1.8;
}

.feature__link, .feature__link span {
  color: var(--green);
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 1s ease;
}

.feature__link svg {
  margin-block-start: 2px;
}

.feature__link:hover, .feature__link:hover span {
  color: var(--darkGreen);
}

@media (min-width: 768px) {
  .feature {
    /* min-height: 300px; */
    /* max-width: 42%; */
    margin-block-end: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .feature {
    width: auto;
    flex: 1;
  }
}