.cartHead {
  display: none;
}

.cartHead__column {
  flex: 1;
  padding: 0 0 10px;
}

.cartHead__column--price {
  width: 140px;
  flex-shrink: 0;
  text-align: right;
  padding-right: 30px;
}

.cartHead__column--quantity {
  text-align: center;
  padding-right: 30px;
  width: calc(2em + 99px);
  padding: 0 0 10px;
  flex-shrink: 0;
}

.cartHead__column--total {
  text-align: right;
  width: 100px;
  padding: 0 0 10px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .cartHead {
    display: flex;
  }
}
